import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {useEventDateInformation} from '../../../commons/hooks/dates'
import {DH} from '../../utils/data-hooks'
import s from './full-date-location.scss'
import {FullDateLocationProps} from '.'

export const FullDateLocation: React.FC<FullDateLocationProps> = ({showDate, showLocation, event}) => {
  const {fullDate} = useEventDateInformation(event.id)
  const getContainerClasses = () => classNames({[s.noDate]: !showDate, [s.noLocation]: !showLocation})

  return (
    <div className={getContainerClasses()} data-hook={DH.fullDateLocation}>
      <div className={s.date} data-hook="date">
        <BrowserOnly>{fullDate}</BrowserOnly>
      </div>
      <div className={s.location} data-hook="location">
        {getFormattedFullLocation(event)}
      </div>
    </div>
  )
}
