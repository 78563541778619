import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {addQueryParams, EVENTS_APP_ID, isRegistrationExternal} from '@wix/wix-events-commons-statics'
import {getSiteStructure} from '../utils/wix-code-api'

export const navigateToDetailsPage = async (
  event: wix.events.Event,
  isDetailsPageEnabled: boolean,
  wixCodeApi: IWixAPI,
) => {
  if (!isRegistrationExternal(event)) {
    const target = getDetailsTarget(event, isDetailsPageEnabled)
    const url = await getDetailsUrl(event, isDetailsPageEnabled, wixCodeApi)
    const queryParams = getQueryParams(wixCodeApi)
    if (wixCodeApi.location.to && url) {
      wixCodeApi.location.to(`${url}/${target}${queryParams}`)
    } else {
      return wixCodeApi.location.navigateToSection({sectionId: 'events', state: target, queryParams: ''})
    }
  }
}

export const getDetailsUrl = async (event: wix.events.Event, isDetailsPageEnabled: boolean, wixCodeApi: IWixAPI) => {
  const url = await wixCodeApi.site.getSectionUrl({sectionId: 'events', appDefinitionId: EVENTS_APP_ID})
  return url?.relativeUrl
}

export const getQueryParams = (wixCodeApi: IWixAPI) => addQueryParams('', wixCodeApi.location.query)

export const getDetailsTarget = (event: wix.events.Event, isDetailsPageEnabled: boolean) => {
  return `${event.slug}${isDetailsPageEnabled ? '' : '/form'}`
}

export const getPage = async (wixCodeApi: IWixAPI, pageId: string) => {
  const {pages} = await getSiteStructure(wixCodeApi)
  return pages.find(page => page.id === pageId)
}

export const getHomePage = async (wixCodeApi: IWixAPI) => {
  const {pages} = await getSiteStructure(wixCodeApi)
  return pages.find(page => page.isHomePage) || pages[0]
}
