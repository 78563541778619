import {DATE_SIZE} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {BrowserOnly} from '../../../../../commons/components/browser-only'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import sc from '../../../classes.scss'
import s from './date.scss'
import {DateProps} from '.'

const DATE_WIDTH = 108

export const Date = ({t, customColorClass = '', size, scheduleTbd, event}: DateProps) => {
  const {fullDate, weekDay, monthDay, month} = useEventDateInformation(event.id)

  return (
    <BrowserOnly>
      <div
        className={classNames(s.listHeaderTitle, sc.row, customColorClass || s.color, [s[DATE_SIZE[size]]])}
        style={{minWidth: DATE_WIDTH}}
        aria-label={fullDate}
        data-hook={scheduleTbd ? 'ev-date-tbd' : 'ev-date'}
      >
        {scheduleTbd ? (
          <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-tbd-text">
            {t('listDateTbd')}
          </div>
        ) : (
          <>
            <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-month-day" aria-hidden="true">
              {monthDay}
            </div>
            <div className={classNames(sc.textNoMargin, s.weekAndMonth)}>
              <div
                className={s.weekDay}
                style={customColorClass ? null : {opacity: 0.5}}
                data-hook="ev-date-weekday"
                aria-hidden="true"
              >
                {weekDay}
              </div>
              <div className={s.month} data-hook="ev-date-month" aria-hidden="true">
                {month}
              </div>
            </div>
          </>
        )}
      </div>
    </BrowserOnly>
  )
}
