import {ImageService} from '@wix/wix-events-commons-statics'
import React from 'react'
import {getImageStyle} from '../../../../commons/utils/image'
import s from './image.scss'
import {ImageProps, ImageState} from './interfaces'

export class Image extends React.Component<ImageProps, ImageState> {
  state: ImageState = {element: null}

  setRef = (element: HTMLElement) => this.setState({element})

  getStyle = () => {
    const {image, opacity, gridPosition, fitImage, square} = this.props
    const {element} = this.state

    const {width: containerWidth, height: containerHeight} = element?.getBoundingClientRect() || {width: 1, height: 1}
    const container = ImageService.getResizedImageSize(image.width, image.height, containerWidth, containerHeight)

    return {
      ...getImageStyle({
        image,
        container,
        opacity,
        position: gridPosition,
        backgroundSize: fitImage ? 'contain' : 'cover',
      }),
      ...(square ? {height: 'auto', paddingTop: '100%'} : {}),
    }
  }

  render() {
    const {image} = this.props

    return image && image.id ? <div className={s.container} style={this.getStyle()} ref={this.setRef} /> : null
  }
}
