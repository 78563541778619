import classNames from 'classnames'
import React from 'react'
import {BUTTONS_STYLE} from '@wix/wix-events-commons-statics'
import sc from '../../classes.scss'
import s from './today-button.scss'
import {TodayButtonProps} from '.'

export const TodayButton = ({onClick, dataHook, isMobile, t, todayButtonText, todayButtonStyle}: TodayButtonProps) => {
  const getStyleClassNames = () => ({
    [s.full]: [BUTTONS_STYLE.FULL, BUTTONS_STYLE.FULL_ROUNDED].includes(todayButtonStyle),
    [s.rounded]: [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(todayButtonStyle),
  })

  return (
    <button
      data-hook={dataHook}
      onClick={() => onClick()}
      type="button"
      className={classNames(s.container, sc.ellipsis, getStyleClassNames(), {[s.mobile]: isMobile})}
    >
      {todayButtonText || t('layout.calendar.monthly.controls.today')}
    </button>
  )
}
