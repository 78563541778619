import i18n from 'i18next'
import {importResources} from './import-18n-resources'

export const ssrI18N = (translation: Dictionary<string>) => {
  const i18nInstance = i18n.createInstance()
  i18nInstance.init({
    resources: {en: {translation}},
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    keySeparator: false,
    react: {
      wait: false,
      useSuspense: false,
    },
  })
  return i18nInstance
}

export const memoizedI18n = (locale: string, resources: string[], staticsBaseUrl) => {
  const i18nInstance = i18n.createInstance()
  i18nInstance
    .use({
      type: 'backend',
      read: async (language, namespace, callback) => {
        return importResources(resources, language, staticsBaseUrl)
          .then(translation => callback(null, translation))
          .catch(error => callback(error))
      },
    })
    .init({
      lng: locale,
      fallbackLng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        useSuspense: true,
      },
    })
  return i18nInstance
}

export default memoizedI18n
