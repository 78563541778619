import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {resizeComponent, setHeight} from '../../../commons/utils/wix-sdk'
import s from './calendar.scss'
import {MonthlyCalendar} from './monthly'
import {MobileMonthlyCalendar} from './monthly/mobile'
import {CalendarProps, CalendarState} from '.'

const MIN_WIDTH = 750
const MIN_MOBILE_WIDTH = 280

export class Calendar extends React.PureComponent<CalendarProps, CalendarState> {
  state: CalendarState = {
    forceMobileLayout: false,
  }

  resize = (width, height) => {
    const {isEditor, isMobile} = this.props

    if (!isEditor && !isMobile) {
      this.props.closeMonthlyCalendarPopup()
    }

    if (width && height) {
      setHeight(height)

      const minWidth = isMobile ? MIN_MOBILE_WIDTH : MIN_WIDTH

      if (width < minWidth) {
        if (isEditor) {
          resizeComponent({width: minWidth, height})
        } else {
          this.setState({forceMobileLayout: true})
        }
      } else {
        this.setState({forceMobileLayout: false})
      }
    }
  }

  render() {
    const mobile = this.props.isMobile || this.state.forceMobileLayout

    return (
      <Resizable resizeFunc={this.resize}>
        <div className={classNames(s.calendar, {[s.mobile]: mobile})} data-hook={'calendar-layout'}>
          {mobile ? <MobileMonthlyCalendar /> : <MonthlyCalendar />}
        </div>
      </Resizable>
    )
  }
}
