import React from 'react'
import classNames from 'classnames'
import {LinkToPage} from '../link-to-page'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

const getButtonClasses = (style: number, fullWidth: boolean, mobile: boolean, link = false) => {
  const classes = [s.button, 'a11yOutline', fullWidth ? s.fullWidth : '', link ? s.link : '']

  if ([2, 4].includes(style)) {
    const radiusClass = style === 4 ? s.evRsvpButtonRoundedBorderRadius : s.evRsvpButtonBorderRadius
    classes.push(s.evRsvpHollowButtonFont, s.evRsvpHollowButtonColor, s.evRsvpButtonBorder, radiusClass)
  }

  if ([1, 3].includes(style)) {
    const radiusClass = style === 3 ? s.evRsvpButtonRoundedBorderRadius : s.evRsvpButtonBorderRadius
    classes.push(s.evRsvpButtonFont, s.evRsvpButtonColor, s.evRsvpButtonBackground, radiusClass)
  }

  return classNames(classes, {[s.mobile]: mobile})
}

export const RsvpButton = ({
  event,
  text,
  style,
  dataHook = 'ev-rsvp-button',
  navigateToPage,
  fullWidth,
  minWidth = 160,
  mobile,
  maxWidth,
  additionalStyle = {},
  withoutRegistration,
  hasUrl,
}: RsvpButtonProps) => {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigateToPage()
  }

  const styles: React.CSSProperties = {
    minWidth,
    maxWidth,
    ...additionalStyle,
    visibility: withoutRegistration ? 'hidden' : 'visible',
  }
  const className = getButtonClasses(style, fullWidth, mobile, hasUrl)

  return hasUrl && !withoutRegistration ? (
    <LinkToPage event={event} dataHook={dataHook} style={styles} className={className}>
      {text}
    </LinkToPage>
  ) : (
    <button data-hook={dataHook} style={styles} className={className} type="button" onClick={onClick}>
      {text}
    </button>
  )
}
