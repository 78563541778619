import classNames from 'classnames'
import React from 'react'
import sc from '../../../../classes.scss'
import {MobileMonthlyDay} from './day'
import s from './mobile-monthly-days.scss'
import {MobileMonthlyDaysProps} from '.'

export const MobileMonthlyDays: React.FC<MobileMonthlyDaysProps> = ({
  calendarDays,
  borderStyleBottom,
  borderStyleTop,
  t,
}: MobileMonthlyDaysProps) => (
  <div className={s.days} style={borderStyleTop}>
    {calendarDays.length ? (
      calendarDays.map(dayWithEvents => (
        <div className={s.day} style={borderStyleBottom}>
          <MobileMonthlyDay dayWithEvents={dayWithEvents} />
        </div>
      ))
    ) : (
      <div className={classNames(s.empty, sc.textLineHeight)} data-hook={'calendar-empty'}>
        {t('layout.calendar.monthly.empty')}
      </div>
    )}
  </div>
)
