import classNames from 'classnames'
import React from 'react'
import {DH} from '../../../utils/data-hooks'
import sc from '../../classes.scss'
import {LinkToPage} from '../../link-to-page'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

export const RsvpButton = ({
  event,
  title,
  text,
  roundedButton,
  hollowButton,
  width,
  height,
  borderRadius,
  onImage,
  roundedBorderRadius,
  navigateToPage,
  isMobile,
  responsiveButton,
  hidden,
  hasUrl,
}: RsvpButtonProps) => {
  const style = {
    minWidth: responsiveButton ? '100%' : width,
    minHeight: height,
    borderRadius: roundedButton ? roundedBorderRadius : borderRadius,
  }

  const className = hollowButton ? s.hollowButton : s.fullButton
  const onImageClassName = hollowButton ? s.onImageHollowButton : s.onImageFullButton

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigateToPage()
  }

  const buttonClassNames = classNames(s.button, sc.textLineHeight, className, {
    [onImageClassName]: onImage,
    [s.link]: hasUrl,
  })

  return (
    <div
      id={DH.rsvpButton}
      className={classNames(s.container, {[s.mobile]: isMobile, [s.responsive]: responsiveButton, [s.hidden]: hidden})}
    >
      {title && (
        <div className={classNames(s.title, {[s.onImageTitle]: onImage})} data-hook="ev-rsvp-button-title">
          {title}
        </div>
      )}
      {hasUrl && !hidden ? (
        <LinkToPage event={event} dataHook="ev-rsvp-button" className={buttonClassNames} style={style}>
          {text}
        </LinkToPage>
      ) : (
        <button style={style} data-hook="ev-rsvp-button" className={buttonClassNames} onClick={onClick}>
          {text}
        </button>
      )}
    </div>
  )
}
