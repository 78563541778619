/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface LinkedinNewProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const LinkedinNew: React.FC<LinkedinNewProps> = ({size, ...props}) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width={ size || "24" } height={ size || "24" } {...props}>
    <defs>
      <path d="M20.452313,20.4506667 L16.893959,20.4506667 L16.893959,14.8804444 C16.893959,13.552 16.8704026,11.8435556 15.0441127,11.8435556 C13.1915997,11.8435556 12.9089226,13.2915556 12.9089226,14.7853333 L12.9089226,20.4506667 L9.35056854,20.4506667 L9.35056854,8.99733333 L12.7649172,8.99733333 L12.7649172,10.5635556 L12.8138079,10.5635556 C13.2889366,9.66266667 14.450313,8.71333333 16.1819327,8.71333333 C19.7869551,8.71333333 20.452313,11.0848889 20.452313,14.1688889 L20.452313,20.4506667 Z M5.33753102,7.43244444 C4.19659987,7.43244444 3.27434349,6.50755556 3.27434349,5.36888889 C3.27434349,4.22888889 4.19659987,3.304 5.33753102,3.304 C6.47535094,3.304 7.40027408,4.22888889 7.40027408,5.36888889 C7.40027408,6.50755556 6.47535094,7.43244444 5.33753102,7.43244444 Z M3.55702063,20.4506667 L7.11804141,20.4506667 L7.11804141,8.99733333 L3.55702063,8.99733333 L3.55702063,20.4506667 Z M22.2248231,0 L1.77117671,0 C0.794251639,0 0,0.775111111 0,1.73066667 L0,22.2684444 C0,23.224 0.794251639,24 1.77117671,24 L22.2248231,24 C23.2039705,24 24,23.224 24,22.2684444 L24,1.73066667 C24,0.775111111 23.2039705,0 22.2248231,0 L22.2248231,0 Z"
        id="path-linkedin-new" />
    </defs>
    <g id="09-Icons-/-Social-/-Linkdin" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="&#x1F3A8;-Color" fill="currentColor">
        <mask id="mask-2">
          <use xlinkHref="#path-linkedin-new" />
        </mask>
        <use id="Mask" xlinkHref="#path-linkedin-new" />
      </g>
    </g>
  </svg>
);
LinkedinNew.displayName = 'LinkedinNew';
export default LinkedinNew;
/* tslint:enable */
/* eslint-enable */
