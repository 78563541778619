import classNames from 'classnames'
import React from 'react'
import {LIST_LOCATION_AND_DATE_FORMAT} from '@wix/wix-events-commons-statics'
import {getListAlignmentClassName, getListImageLayoutClassName} from '../../../selectors/classes'
import {Image} from '../../commons/image'
import {FullDateLocation} from '../../full-date-location'
import {LinkToPage} from '../../link-to-page'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import s from './side-by-side-item.scss'
import {SideBySideItemProps} from '.'

export const SideBySideItem = ({
  event,
  evenItem,
  showFullDate,
  showLocation,
  showDescription,
  showSocialBar,
  imageLayout,
  alignment,
  locationAndDateFormat,
  showImage,
  showMembers,
  imageWidth,
  imageOpacity,
  imagePosition,
  membersEnabled,
  t,
  showRibbon,
  showDate,
  showVenue,
}: SideBySideItemProps) => {
  const getContainerClasses = () => {
    return classNames(
      s.container,
      s[`${getListImageLayoutClassName(imageLayout)}Image`],
      s[getListAlignmentClassName(alignment)],
      {
        [s.even]: evenItem,
        [s.withFullDateLocation]: locationAndDateFormat === LIST_LOCATION_AND_DATE_FORMAT.FULL,
        [s.noFullDateLocation]: !showFullDate && !showLocation,
        [s.noDescription]: !showDescription || !event.description,
        [s.noSocialBar]: !showSocialBar,
      },
    )
  }

  const renderShortDateAndLocation = () => {
    return (
      locationAndDateFormat === LIST_LOCATION_AND_DATE_FORMAT.COMPACT &&
      (showDate || showVenue) && (
        <div className={classNames(s.shortDateLocation, s.cardContent)}>
          <ShortDateLocation event={event} alignment={alignment} showDate={showDate} showLocation={showVenue} />
        </div>
      )
    )
  }

  const renderFullDateAndLocation = () => {
    return (
      locationAndDateFormat === LIST_LOCATION_AND_DATE_FORMAT.FULL &&
      (showFullDate || showLocation) && (
        <div className={classNames(s.fullDateLocation, s.cardContent)}>
          <FullDateLocation event={event} showDate={showFullDate} showLocation={showLocation} />
        </div>
      )
    )
  }

  return (
    <div className={getContainerClasses()}>
      {showImage ? (
        <div className={s.imageContainer} style={{width: `${imageWidth}%`}}>
          <div className={s.imageOverlay} data-hook="image-background" />
          <div className={s.image} data-hook="image">
            <Image image={event.mainImage} opacity={imageOpacity} gridPosition={imagePosition} />
          </div>
        </div>
      ) : null}
      <div className={s.content}>
        {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
        <div className={s.details}>
          {renderShortDateAndLocation()}
          <div className={s.title} data-hook="title">
            <LinkToPage event={event}>{event.title}</LinkToPage>
          </div>
          {renderFullDateAndLocation()}
          {showDescription && !!event.description && (
            <div className={s.description} data-hook="description">
              {event.description}
            </div>
          )}
          {!membersEnabled && (
            <div className={classNames(s.socialBar, s.cardContent)}>
              <SocialBar t={t} event={event} />
            </div>
          )}
        </div>
        {showMembers ? (
          <div className={s.members} data-hook="members">
            <Members event={event} />
          </div>
        ) : null}
        <div className={s.button}>
          <RsvpButton event={event} />
        </div>
        {membersEnabled && (
          <div className={classNames(s.socialBar, s.bellowContent, s.cardContent)}>
            <SocialBar t={t} event={event} />
          </div>
        )}
      </div>
    </div>
  )
}
