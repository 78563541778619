import {withTranslation} from 'react-i18next'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps} from './interfaces'
import {MonthlyCalendar as MonthlyCalendarPresentation} from './monthly-calendar'

const mapRuntime = ({actions, state}: AppProps): MonthlyCalendarRuntimeProps => ({
  fullMonthText: state.calendarLayout.fullMonthText,
  addCalendarMonth: actions.addCalendarMonth,
  subCalendarMonth: actions.subCalendarMonth,
  resetCalendar: actions.resetCalendar,
})

export const MonthlyCalendar = connect<MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps>(mapRuntime)(
  withTranslation()(MonthlyCalendarPresentation),
)
export * from './interfaces'
