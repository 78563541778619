import {getFormattedLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {useEventDateInformation} from '../../../commons/hooks/dates'
import {getAlignmentClassName, getListAlignmentClassName} from '../../selectors/classes'
import sc from '../classes.scss'
import s from './short-date-location.scss'
import {ShortDateLocationProps} from '.'

export const ShortDateLocation = ({event, alignment, showDate, showLocation}: ShortDateLocationProps) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  return (
    <div
      className={classNames(s.container, sc.textLineHeight, s[getListAlignmentClassName(alignment)], {
        [s.noDate]: !showDate,
        [s.noLocation]: !showLocation,
      })}
      data-hook="ev-short-date-location"
    >
      <div className={s.date} data-hook="short-date">
        <BrowserOnly>{shortStartDate}</BrowserOnly>
      </div>
      <div className={s.divider} />
      <div className={s.location} data-hook="short-location">
        {getFormattedLocation(event)}
      </div>
    </div>
  )
}

export const SingleEventShortDateLocation = ({
  event,
  alignment,
  showDate,
  showLocation,
  onImage,
  mobile,
}: ShortDateLocationProps) => {
  const {startDate} = useEventDateInformation(event.id)
  return (
    <div
      className={classNames(
        onImage ? s.onImageCompactTextsFont : s.compactTextsFont,
        s.singleEventContainer,
        sc.textLineHeight,
        s[getAlignmentClassName(alignment)],
        {
          [s.noDate]: !showDate,
          [s.noLocation]: !showLocation,
          [s.mobile]: mobile,
        },
      )}
      data-hook="ev-short-date-location"
    >
      <div className={s.dateWrapper}>
        <div className={s.date} data-hook="short-date">
          <BrowserOnly>{startDate}</BrowserOnly>
        </div>
        <div className={s.divider} />
      </div>
      <div className={s.location} data-hook="short-location">
        {getFormattedLocation(event)}
      </div>
    </div>
  )
}
